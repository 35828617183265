.verify-loading {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}
.verify-loading .cancelBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  margin: auto;
  top: 280px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 88px;
  height: 32px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
}
.verify-loading .cancelBtn:hover {
  background: rgba(255, 255, 255, 0.1);
}
.verify-loading h1 {
  position: absolute;
  height: 40px;
  margin: auto;
  top: 210px;
  left: 0;
  right: 0;
  bottom: 0;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.1em;
  color: white;
  font-size: 20px;
}
.verify-loading img {
  width: 300px;
  height: 300px;
}
.verify-loading p {
  margin-top: 20px;
  color: #999999;
  font-size: 14px;
  line-height: 14px;
}
.verify-loading .loading-line {
  position: absolute;
  left: calc(50% - 70px);
  top: calc(50% - 100px);
  width: 140px;
  height: 140px;
  z-index: 1;
}
.verify-loading .loading-line div {
  position: absolute;
  width: 12px;
  height: 38px;
  border-radius: 6px;
  background: #5d9cf5;
  animation: load 1.04s ease infinite;
  -webkit-animation: load 1.04s ease infinite;
}
.verify-loading .loading-line div:nth-child(1) {
  left: 13px;
  top: 50%;
  margin-top: -15px;
  transform: rotate(270deg);
  animation-delay: 0.13s;
  -webkit-animation-delay: 0.13s;
  opacity: 1;
}
.verify-loading .loading-line div:nth-child(2) {
  left: 25px;
  top: 15px;
  transform: rotate(315deg);
  animation-delay: 0.26s;
  -webkit-animation-delay: 0.26s;
  opacity: 0.9;
}
.verify-loading .loading-line div:nth-child(3) {
  left: 50%;
  top: 0;
  margin-left: -6px;
  transform: rotate(0);
  animation-delay: 0.39s;
  -webkit-animation-delay: 0.39s;
  opacity: 0.8;
}
.verify-loading .loading-line div:nth-child(4) {
  top: 15px;
  right: 25px;
  transform: rotate(45deg);
  animation-delay: 0.52s;
  -webkit-animation-delay: 0.52s;
  opacity: 0.7;
}
.verify-loading .loading-line div:nth-child(5) {
  right: 13px;
  top: 50%;
  margin-top: -15px;
  transform: rotate(90deg);
  animation-delay: 0.65s;
  -webkit-animation-delay: 0.65s;
  opacity: 0.6;
}
.verify-loading .loading-line div:nth-child(6) {
  right: 25px;
  bottom: 15px;
  transform: rotate(135deg);
  animation-delay: 0.78s;
  -webkit-animation-delay: 0.78s;
  opacity: 0.5;
}
.verify-loading .loading-line div:nth-child(7) {
  bottom: 0;
  left: 50%;
  margin-left: -6px;
  transform: rotate(180deg);
  animation-delay: 0.91s;
  -webkit-animation-delay: 0.91s;
  opacity: 0.4;
}
.verify-loading .loading-line div:nth-child(8) {
  bottom: 15px;
  left: 25px;
  transform: rotate(225deg);
  animation-delay: 1.04s;
  -webkit-animation-delay: 1.04s;
  opacity: 0.3;
}
@keyframes load {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
@-webkit-keyframes load {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
@keyframes rt {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes cw {
  0% {
    width: 0;
    height: 0;
  }
  75% {
    width: 40px;
    height: 40px;
  }
  100% {
    width: 0;
    height: 0;
  }
}
