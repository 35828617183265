.blog-tree {
  height: 100%;
  overflow-y: auto;
}
.blog-tree::-webkit-scrollbar {
  width: 0;
}
.blog-tree > div {
  height: 28px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}
.blog-tree > div .tree-arrow {
  margin-right: 8px;
  width: 14px;
  height: 14px;
  display: flex;
}
.blog-tree > div .tree-arrow svg {
  transform: rotate(0deg);
  transition: all 0.5s;
}
.blog-tree > div .tree-text {
  flex: 1;
  font-size: 13px;
  line-height: initial;
  white-space: nowrap;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
}
.blog-tree-selected .tree-text {
  color: #1677ff !important;
}
.dark-tree .tree-arrow svg {
  color: #cccccc;
}
.dark-tree .tree-text {
  color: #cccccc;
}
.light-tree .tree-arrow svg {
  fill: #999999;
}
.light-tree .tree-text {
  color: #666666;
}
.light-tree .tree-text:hover {
  color: #1677ff !important;
}
.indent-level-1 {
  padding-left: 8px;
}
.indent-level-2 {
  padding-left: 16px;
}
.indent-level-3 {
  padding-left: 24px;
}
.indent-level-4 {
  padding-left: 32px;
}
.indent-level-5 {
  padding-left: 40px;
}
.indent-level-6 {
  padding-left: 48px;
}
