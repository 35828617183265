.examwarn-modal .ant-modal-content {
  padding: 24px;
}
.examwarn-modal .examwarn-title {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  color: #f5222d;
  line-height: 24px;
  margin-bottom: 12px;
}
.examwarn-modal .examwarn-title img {
  width: 22px;
  height: 22px;
  margin-right: 16px;
}
.examwarn-modal .examwarn-content {
  padding-left: 38px;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #666666;
  line-height: 22px;
  margin-bottom: 24px;
}
.examwarn-modal .examwarn-content .red {
  color: #F5222D;
}
