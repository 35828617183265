.joinModal {
  overflow: hidden;
}
.joinModal .ant-modal-close {
  display: none !important;
}
.modalAll1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  overflow-y: scroll;
}
.modalAll1::-webkit-scrollbar {
  display: none;
}
.modalAll1 .ant-space-item .ant-checkbox-wrapper {
  line-height: 22px !important;
}
.modalAll1 .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  background-color: #fff;
}
.modalAll1 .ant-radio-wrapper .ant-radio-checked .ant-radio-inner::after {
  transform: scale(0.65);
  background-color: #1677FF;
}
.modalAll1 label {
  font-size: 14px;
  font-weight: 400;
  color: #333333 !important;
}
.modalAll1 .ant-form-item-label label {
  font-size: 14px !important;
  font-weight: 400;
  color: #333333 !important;
}
.modalAll1 .onClick .ant-form-item-label::after {
  content: '\5355\9009' !important;
  width: 32px;
  height: 16px;
  background: rgba(22, 119, 255, 0.05);
  border: 0.5px solid #1677ff;
  border-radius: 2px;
  font-size: 12px;
  color: #1677ff;
  padding: 2px 4px;
  margin-left: 4px;
}
.modalAll1 .towClick .ant-form-item-label::after {
  content: '\591a\9009' !important;
  width: 32px;
  height: 16px;
  background: rgba(22, 119, 255, 0.05);
  border: 0.5px solid #1677ff;
  border-radius: 2px;
  font-size: 12px;
  color: #1677ff;
  padding: 2px 4px;
  margin-left: 4px;
}
.modalAll1 .ant-input {
  border-radius: 2px !important;
}
.modalAll1 .ant-checkbox-inner {
  border-radius: 0;
}
.modalAll1 .modalAll-title {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  margin-top: 10px;
}
.modalAll1 .bottomButs {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.modalAll1 .jionText {
  position: absolute;
  font-size: 12px;
  font-weight: 400;
  color: #f5222d;
  top: -5px;
}
.modalAll1 .bottonLeft {
  width: 88px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  color: #666666;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.modalAll1 .bottonLeft:hover {
  border: 1px solid #1677FF;
  color: #1677FF;
}
.modalAll1 .bottonRigth {
  width: 88px;
  height: 32px;
  background: #1677ff;
  border-radius: 2px;
  color: #fff;
  margin-left: 30px;
}
