.two {
  width: 100%;
  border: 0.5px solid #e5e5e5;
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 15px;
  transition: all 0.3s;
}
.two-section {
  width: 100%;
  height: 40px;
  background: #f9fafc;
  display: flex;
  align-items: center;
  padding-left: 20px;
}
.two-section span {
  font-size: 14px;
  font-weight: 400;
  text-align: LEFT;
  color: #333333;
  line-height: 14px;
  margin-right: 8px;
}
.two-section .towTitle {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
}
.two-section-btn {
  display: flex;
  justify-content: end;
  padding-right: 20px;
}
.two-section-btn-img {
  cursor: pointer;
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  border-radius: 50%;
  background: #ffffff;
  border: 0.5px solid #1677ff;
  box-shadow: 0px 2px 4px 0px rgba(97, 92, 153, 0.15);
}
.two-section-btn-img img {
  width: 100%;
  height: 100%;
  transition: all 0.4s;
}
.two-section-btn-img .change0 {
  transform: rotate(0deg);
}
.two-section-btn-img .change180 {
  transform: rotate(180deg);
}
.two-section-btn-img:hover {
  background: #e8f1ff;
}
.two-one {
  width: 100%;
  padding: 8px 10px;
}
