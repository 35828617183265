.howgetcode-title {
  font-size: 12px;
  font-weight: 400;
  text-align: LEFT;
  color: #999999;
  line-height: 14px;
  cursor: pointer;
}
.howgetcode-title:hover {
  color: #1677ff;
}
.howgetcode-modal .ant-modal-close {
  display: block !important;
}
.howgetcode-modal .ant-modal-content {
  padding: 40px 90px 60px 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.howgetcode-modal .title {
  font-size: 18px;
  font-weight: 400;
  text-align: CENTER;
  color: #333333;
  line-height: 18px;
  margin-bottom: 40px;
}
.howgetcode-modal img {
  width: 200px;
}
