.inviteModal {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.inviteModal .ant-modal-close {
  display: none !important;
}
.inviteModal .invitecontant {
  position: relative;
}
.inviteModal .invitecontant-top {
  display: flex;
  align-items: center;
}
.inviteModal .invitecontant-top-text {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  margin-left: 16px;
}
.inviteModal .invitecontant-text {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  margin-left: 38px;
  margin-top: 10px;
  padding-bottom: 55px;
}
.inviteModal .invitecontant-btns {
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
}
.inviteModal .invitecontant-btns .botton1 {
  width: 88px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  color: #666666;
  margin-left: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.inviteModal .invitecontant-btns .botton1:hover {
  border: 1px solid #1677FF;
  color: #1677FF;
}
.inviteModal .invitecontant-btns .botton2 {
  width: 88px;
  height: 32px;
  background: #1677ff;
  border-radius: 2px;
  color: #fff;
}
