.create-course {
  border-radius: 10px;
}
.create-course-main .steps {
  padding-top: 30px;
  margin: 0 auto;
}
.create-course-main .steps-action {
  position: relative;
  padding-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.create-course-main .steps-action .ant-btn {
  margin: 0 30px;
  width: 96px;
  height: 40px;
  border-radius: 2px;
}
.create-course-main .steps-action .ant-btn-default {
  color: #1677ff;
  border-color: #1677ff;
}
.create-course-main .steps-action .ant-btn-primary:disabled {
  border-color: #cccccc;
  color: #ffffff;
  background-color: #cccccc;
}
.create-course-main .steps-action .draft {
  position: absolute;
  right: 0;
  top: 0;
  color: #1677ff;
  text-decoration: underline;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
}
.create-course-main .steps-action .draft-disabled {
  cursor: not-allowed;
  color: #cccccc;
}
.create-course-main .botton1 {
  width: 96px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #1677ff;
  border-radius: 2px;
  color: #1677ff;
  margin-left: 200px;
}
.create-course .ant-modal .ant-modal-content {
  border-radius: 4px;
}
.create-course .ant-modal .ant-modal-content .ant-modal-confirm-btns {
  margin-top: 23px;
}
.create-course .ant-modal-confirm .ant-modal-confirm-btns .ant-btn {
  border-radius: 2px;
}
.create-course .ant-modal-confirm .ant-modal-confirm-btns .ant-btn + .ant-btn {
  margin-inline-start: 10px;
}
.goRevise {
  justify-content: center;
  align-items: center;
  width: 88px;
  height: 32px;
  background: #2f54eb;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 14px;
  display: flex;
  margin-top: 23px;
  margin-left: 280px;
  cursor: pointer;
}
.goRevise:hover {
  background: #4592FF;
}
