.remark-left {
  flex: 1;
  width: 0;
}
.remark-left .remark-title {
  font-size: 20px;
  font-weight: 400;
  color: #333333;
  line-height: 28px;
}
.remark-left .remark-info {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.remark-left .remark-info .remark-stuta {
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 2px 8px 4px;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  line-height: 12px;
}
.remark-left .remark-info .remark-message {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  color: #999999;
  margin-left: 10px;
}
.remark-left .questList {
  width: 100%;
  margin-top: 20px;
}
.remark-left .questList .questListList {
  width: 100%;
  height: 0.5px;
  background: #d9d9d9;
  margin: 0 0 16px 0;
}
.remark-left .remark-Pagination {
  margin-top: 16px;
  display: flex;
  justify-content: end;
}
.remark-left .remark-Pagination .remark-Pagination-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 88px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  margin-right: 8px;
}
.remark-left .remark-Pagination .remark-Pagination-btn:hover {
  border: 1px solid #1677ff;
  color: #1677FF;
  cursor: pointer;
}
.remark-left .remark-Pagination .ant-pagination .ant-pagination-prev {
  width: 32px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 2px !important;
}
.remark-left .remark-Pagination .ant-pagination-next {
  width: 32px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 2px !important;
}
.remark-left .remark-Pagination .ant-pagination-item-link {
  border-radius: 0px !important;
}
.remark-left .remark-Pagination .ant-pagination-item {
  background: #ffffff !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 2px !important;
}
.remark-left .remark-Pagination .ant-pagination .ant-pagination-item-active a {
  background: #1677ff;
  color: #fff;
}
.remark-left .remark-Pagination .ant-pagination .ant-pagination-item-active:hover a {
  color: #fff !important;
}
.remark-left .remark-Pagination .anticon {
  vertical-align: 0em;
}
.remark-left .remark-Pagination .ant-select-single .ant-select-selector {
  border-radius: 2px !important;
}
.remark-left .remark-Pagination .ant-pagination .ant-pagination-options-quick-jumper input {
  border-radius: 2px !important;
}
.remark-left .remark-comment {
  width: 100%;
  display: flex;
  margin-top: 30px;
}
.remark-left .remark-comment .remark-comment-logo {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}
.remark-left .remark-comment .remark-comment-content {
  flex: 1;
  width: 0;
}
.remark-left .remark-comment .remark-comment-content .btn-remark {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  width: 64px;
  height: 32px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
}
