.RemarkAnswer-item {
  display: flex;
  width: 100%;
  align-items: start;
  border-radius: 4px;
  padding: 20px;
  position: relative;
}
.RemarkAnswer-item .line {
  position: absolute;
  width: calc(100% - 40px);
  border-top: 0.5px solid #d9d9d9;
  top: 0;
}
.RemarkAnswer-item .userImg {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin-right: 10px;
}
.RemarkAnswer-item .userText {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.RemarkAnswer-item .userText .userText-userName {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  color: #333333;
}
.RemarkAnswer-item .userText .userText-bug {
  font-size: 14px;
  font-weight: 400;
  color: #faad14;
  margin-top: 16px;
}
.RemarkAnswer-item .userText .userText-value {
  flex: 1;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
}
.RemarkAnswer-item .userText .userText-value .w-e-text-container {
  background: transparent !important;
}
.RemarkAnswer-item .userText .userText-value div {
  max-width: 100%;
}
.RemarkAnswer-item .userText .userText-value p {
  max-width: 100%;
}
.RemarkAnswer-item .userText .userText-value img {
  max-width: 100%;
}
.RemarkAnswer-item .userText .userText-value span {
  max-width: 100%;
}
