.header-container {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: var(--header-height);
  background-color: #fff;
  z-index: 998;
  box-shadow: 0px 4px 20px 0px rgba(92, 117, 153, 0.1);
}
.header-container-content {
  max-width: var(--container-max-width);
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.header-container-content-logo {
  width: 120px;
  cursor: pointer;
}
.header-container-content-minilogo {
  width: 28px;
  height: 28px;
  cursor: pointer;
}
.header-container-content-lablogo {
  margin-left: 8px;
  display: flex;
  align-items: center;
}
.header-container-content-lablogo span {
  display: inline-block;
  width: 0.5px;
  height: 20px;
  background: #d9d9d9;
}
.header-container-content-lablogo img {
  margin-left: 8px;
  height: 28px;
  border: 0.5px solid #e5e5e5;
  object-fit: cover;
  cursor: pointer;
}
.header-container-content-center {
  flex: 1;
}
.header-container-content-center-nav {
  padding-left: 110px;
}
.header-container-content-center-nav-tabs {
  display: flex;
  align-items: center;
  gap: 60px;
  position: relative;
}
.header-container-content-center-nav-tabs span {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 14px;
  cursor: pointer;
  text-align: center;
  min-width: 34px;
}
.header-container-content-center-nav-tabs-line {
  position: absolute;
  bottom: -10px;
  width: 34px;
  height: 4px;
  background: var(--main-color);
  border-radius: 2px;
  left: 0;
  transition: 0.3s;
}
.header-container-content-right .sharefriend {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
}
.header-container-content-right .sharefriend img {
  width: 16px;
  height: 16px;
  margin: 0;
}
.header-container-content-right .sharefriend span {
  margin-left: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #1677ff;
  line-height: 14px;
}
.header-container-content-right .sharefriend:hover span {
  text-decoration: underline;
}
.header-container-content-right-login {
  display: flex;
}
.header-container-content-right-login-user {
  display: flex;
  align-items: center;
}
.header-container-content-right-login-user-logo {
  width: 30px;
  height: 30px;
  margin-right: 7px;
  border-radius: 30px;
}
.header-container-content-right-login-user-name {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.header-container-content-right-login-user-name span {
  font-size: 16px;
  font-weight: 400;
  text-align: LEFT;
  color: #333333;
  line-height: 16px;
  margin-right: 4px;
}
.header-container-content-right-login-user-name img {
  width: 16px;
  height: 16px;
}
.wsharefriend-modal .ant-modal-content {
  padding: 30px;
}
.wsharefriend-modal .ant-modal-close {
  display: block !important;
}
.wsharefriend-modal-title {
  height: 18px;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  color: #333333;
  line-height: 18px;
  margin-bottom: 16px;
}
.wsharefriend-modal-con {
  display: flex;
  margin-bottom: 20px;
}
.wsharefriend-modal-con img {
  margin-top: 2px;
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.wsharefriend-modal-con div {
  font-size: 14px;
  font-weight: 400;
  text-align: LEFT;
  color: #666666;
  line-height: 20px;
}
.wsharefriend-modal-btn {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-bottom: 20px;
}
.wsharefriend-modal-btn input {
  font-size: 14px !important;
  color: #999999 !important;
}
.wsharefriend-modal-btn-share {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #333333;
  line-height: 20px;
}
.wsharefriend-modal-btn-new {
  width: 20px;
  height: 20px;
  cursor: pointer;
  transform: translateY(-2px);
}
.wsharefriend-modal-btn-new img {
  width: 100%;
  height: 100%;
}
.wsharefriend-modal-btn button {
  cursor: pointer;
  width: 64px;
  height: 32px;
  background: #1677ff;
  border-radius: 2px;
  border: none;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  line-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wsharefriend-modal-people {
  display: flex;
  align-items: center;
}
.wsharefriend-modal-people img {
  width: 16px;
  height: 16px;
}
.wsharefriend-modal-people span {
  margin-left: 4px;
  font-size: 14px;
  font-weight: 400;
  text-align: LEFT;
  color: #999999;
  line-height: 14px;
}
.ant-modal .ant-modal-content {
  border-radius: 4px !important;
}
.ant-modal-confirm .ant-modal-confirm-body .ant-modal-confirm-content {
  color: #666 !important;
}
.ant-btn {
  border-radius: 2px !important;
}
.ant-modal-confirm .ant-modal-confirm-btns {
  margin-top: 23px !important;
}
.ant-modal-confirm .ant-modal-confirm-btns .ant-btn-primary {
  background: #1677ff !important;
}
.ant-modal-confirm .ant-modal-confirm-btns .ant-btn-primary:hover {
  background: #4592FF !important;
}
.ant-modal-confirm .ant-modal-confirm-btns .ant-btn-default:hover {
  border-color: #1677ff !important;
  color: #1677ff !important;
}
