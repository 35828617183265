.image-verify {
  height: 100%;
  min-height: calc(100vh - 200px);
  margin: 0 30px;
}
.image-verify .title {
  padding-top: 20px;
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  display: flex;
  align-items: center;
}
.image-verify .title-line {
  width: 4px;
  height: 20px;
  background: #1677ff;
  border-radius: 2px;
  margin-right: 10px;
  transform: translateY(1px);
}
.image-verify .verify-content {
  margin-top: 20px;
  position: relative;
  border: 1px solid #d9d9d9;
  margin-bottom: 30px;
  border-radius: 4px;
}
.image-verify .verify-content .content {
  height: calc(100vh - 300px);
  overflow-y: hidden ;
}
.image-verify .verify-content .content .mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
}
.image-verify .verify-content .preview {
  position: absolute;
  top: 0;
  right: 0;
  width: 124px;
  height: 40px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: #ffffff;
  font-size: 16px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  z-index: 99;
}
.image-verify .couse-name {
  font-size: 20px;
  font-weight: 500;
  color: #333333;
}
