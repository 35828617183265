.blog-detail {
  margin-top: 16px;
  height: calc(100vh - 120px);
  display: flex;
  background: #ffffff;
  border-radius: 10px;
  overflow-x: hidden;
  overflow-y: auto;
}
.blog-detail::-webkit-scrollbar {
  width: 0;
}
.blog-detail-left {
  width: calc(100% - 300px);
  min-height: 100%;
  height: fit-content;
  border-right: 1px solid #d9d9d9;
}
.blog-detail-left .line {
  margin: 0 30px;
  height: 0.5px;
  background: #d9d9d9;
}
.blog-detail-left .blog-md {
  width: 100%;
}
.blog-detail-left .blog-md .tiptap-title {
  display: none;
}
.blog-detail-left .markdown {
  max-width: 100% !important;
}
.blog-detail-left .markdown .otherHeading {
  margin: 10px 0 !important;
}
.blog-detail-left .markdown pre {
  overflow: visible !important;
  margin: 1em 0;
}
.blog-detail-left .markdown th {
  background-color: #f7f9fc !important;
  /* 设置表头背景颜色 */
  font-size: 15px !important;
  color: #333333 !important;
  font-weight: normal;
  /* 取消标题的加粗效果 */
  text-align: left;
}
.blog-detail-left .markdown tr {
  background-color: #ffffff !important;
  /* 设置偶数行背景颜色 */
  font-size: 14px !important;
  color: #666;
  text-align: left !important;
}
.blog-detail-left .markdown .readerTextContain {
  width: 100%;
  max-width: 1300px !important;
  padding: 10px 30px !important;
}
.blog-detail-left .markdown .readerTextContain blockquote {
  width: 100% !important;
  box-sizing: border-box;
  margin: 1em 0 !important;
}
.blog-detail-left .markdown .readerTextContain .execSpan .hljs {
  box-sizing: border-box;
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.blog-detail-left .markdown .readerTextContain .execSpan .hljs:hover::-webkit-scrollbar-thumb {
  background: #444;
  border-radius: 2px;
}
.blog-detail-left .markdown .readerTextContain .execSpan .hljs::-webkit-scrollbar {
  height: 6px;
}
.blog-detail-left .markdown .readerTextContain .execSpan .hljs::-webkit-scrollbar-track {
  background: transparent;
}
.blog-detail-left .markdown .readerTextContain .execSpan .hljs::-webkit-scrollbar-thumb {
  border-radius: 2px;
}
.blog-detail-left .markdown .readerTextContain .execSpan .hljs::-webkit-scrollbar-thumb:hover {
  background: #666;
}
.blog-detail-right {
  position: relative;
  padding: 0 30px;
  width: 300px;
}
.blog-detail-right-startEnv {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  padding: 20px 30px;
  background: #ffffff;
  z-index: 1;
}
.blog-detail-right-startEnv .ant-btn {
  width: 100%;
  height: 40px;
}
.blog-detail-right-bar {
  overflow-y: auto;
  padding-bottom: calc(56px + 20px);
}
.blog-detail-right-bar::-webkit-scrollbar {
  width: 0;
}
.blog-detail-layout {
  position: relative;
}
.blog-detail-layout-footer {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 300px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 1px -2px 4px 0px rgba(92, 117, 153, 0.1);
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 14px;
  background: #ffffff;
}
.blog-detail-layout-footer img {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}
.blog-detail-layout-footer .span {
  color: #1677FF;
  cursor: pointer;
  text-decoration: underline;
}
