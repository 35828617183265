.publish-course {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  min-height: calc(100vh - 100px - 100px - 20px);
}
.publish-course .finishImg {
  margin-top: 30px;
  width: 200px;
  height: 200px;
}
.publish-course-info {
  margin-top: 20px;
}
.publish-course-info .tips {
  padding: 22px 0 15px;
  min-width: 400px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
}
.publish-course-info .tips img {
  width: 22px;
  height: 22px;
}
.publish-course-info .tips p {
  margin-left: 10px;
  font-size: 18px;
  font-weight: 600;
  color: #333333;
  line-height: 18px;
}
.publish-course-info .info {
  padding-top: 15px;
}
.publish-course-info .info-title {
  position: relative;
  padding-left: 14px;
  margin-bottom: 30px;
  font-size: 18px;
  font-weight: 600;
  color: #333333;
  line-height: 18px;
}
.publish-course-info .info-title::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 4px;
  height: 18px;
  background: #1677ff;
  border-radius: 1px;
  content: '';
}
.publish-course-info .info-i {
  padding-left: 14px;
}
.publish-course-info .info-i p {
  margin-bottom: 20px;
}
.publish-course-info .info-i p span {
  color: #666666;
}
.publish-course-info .info-i p span:nth-child(1) {
  color: #333333;
  font-weight: 600;
}
.publish-course-info .info-i p .hh {
  transform: translateY(-3px);
  line-height: 22px;
  max-width: 500px;
  overflow: hidden;
}
.publish-course-info .info-i-url {
  display: flex;
  align-items: flex-start;
}
.publish-course-info .info-i-url .copy {
  cursor: pointer !important;
}
.publish-course-info .info-i-mo {
  max-width: 500px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.publish-course-info .info-i-mozk {
  word-break: break-all;
  max-width: 500px;
  line-height: 22px;
  transform: translateY(-3px);
}
.publish-course-info .info-i-zk {
  height: 14px;
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
  text-align: LEFT;
  color: #1677ff !important;
  line-height: 14px;
  margin-left: 10px;
}
