.work-heard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  padding: 0 16px;
}
.work-heard .work-heard-left {
  display: flex;
  align-items: center;
}
.work-heard .work-heard-left-text1 {
  font-size: 18px;
  font-weight: 500;
  color: #1677ff;
}
.work-heard .work-heard-left-text2 {
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  max-width: 360px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-user-select: none;
  user-select: none;
  white-space: nowrap;
  word-break: break-all;
}
.work-heard .work-heard-rigth {
  display: flex;
  align-items: center;
}
.work-heard .work-heard-rigth-run {
  margin-right: 10px;
}
.work-heard .work-heard-rigth .rigth-text {
  font-size: 20px;
  font-weight: 500;
  color: #f5222d;
  padding-right: 10px;
  border-right: 0.5px solid #666666;
  line-height: 20px;
}
.work-heard .work-heard-rigth .rigth-btn {
  cursor: pointer;
  padding: 8px;
}
.work-heard .work-heard-rigth .rigth-btn:hover {
  background: #252525;
  border-radius: 2px;
}
.timeOver .ant-modal .ant-modal-close {
  display: none;
}
.timeOver .ant-modal-footer {
  margin-top: 0 !important;
}
.timeOver .timeOver-top {
  display: flex;
  align-items: center;
}
.timeOver .timeOver-top .timeOver-text {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  margin-left: 16px;
}
.timeOver .timeOver-text1 {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  margin-top: 12px;
  margin-left: 38px;
}
.timeOver .timeOver-bottom {
  width: 100%;
  display: flex;
  justify-content: end;
  margin-top: 23px;
}
.timeOver .timeOver-bottom .timeOver-btn {
  width: 123px;
  height: 32px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  background: #1677ff;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
