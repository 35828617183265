.login-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 60px;
}
.login-header span {
  font-size: 20px;
  font-weight: 600;
  text-align: CENTER;
  color: #666666;
  line-height: 20px;
  padding-bottom: 10px;
  cursor: pointer;
}
.login-header span:hover {
  color: #333333;
}
.login-header .active {
  cursor: default;
  color: #333333;
  position: relative;
}
.login-header .active::before {
  position: absolute;
  content: '';
  left: 0;
  bottom: 2px;
  width: 100%;
  height: 2px;
  background: #1677ff;
  border-radius: 2px;
}
