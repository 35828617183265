.answer-remark {
  position: relative;
  background-color: #F7F8FA;
  min-height: calc(100vh);
  margin-top: 48px;
}
.answer-remark-main {
  margin: 0 auto 20px;
  padding: 15px 0;
  max-width: var(--container-max-width);
  height: 100%;
}
