body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* html, body , #root {
  min-width: 1260px;
  overflow: auto;
  height: auto;
} */

p {
  margin-bottom: 0;
}

.ant-message {
  top: 94px !important;
}

.ant-modal-confirm .ant-modal-confirm-btns .ant-btn-primary {
  background-color: #2f54eb;
}

.ant-modal-confirm .ant-modal-confirm-btns .ant-btn-primary:not(:disabled):hover {
  background-color: #597ef7;
}

.ant-modal-confirm .ant-modal-confirm-btns .ant-btn-default:not(:disabled):hover {
  color: #2f54eb;
  border-color: #2f54eb;
}

.ant-tooltip .ant-tooltip-inner {
  box-shadow: 2px 2px 4px 0px rgba(92, 104, 153, 0.15);
  color: #2f54eb;
}

.ant-table-header .ant-table-cell-scrollbar {
  display: none;
}

.ant-table-cell-fix-right-first {
  right: 0 !important;
}

@media screen and (max-width: 1024px) {
  html {
    font-size: 71px;
  }
}

@media screen and (min-width: 1024px) {
  html {
    font-size: 71px;
  }
}

@media screen and (min-width: 1100px) {
  html {
    font-size: 76px;
  }
}

@media screen and (min-width: 1280px) {
  html {
    font-size: 89px;
  }
}

@media screen and (min-width: 1366px) {
  html {
    font-size: 95px;
  }
}

@media screen and (min-width: 1440px) {
  html {
    font-size: 100px;
  }
}

/* antd中的按钮圆角修改 */
.ant-btn {
  border-radius: 2px;
}

/* antd中的输入框圆角修改 */
.ant-input-affix-wrapper,
.ant-input {
  border-radius: 2px;
}

/* 修改全局 ant-tooltip 样式*/
.platfrom-tooltip-1 {
  /* height: 16px; */
  background: #f5f6fa;
  border: 0.5px solid #e5e5e5;
  border-radius: 2px;
  box-shadow: 1px 1px 1px 0px rgba(92, 117, 153, 0.10);
  overflow: hidden;
}

.platfrom-tooltip-1 .ant-tooltip-inner {
  min-height: 16px !important;
  padding: 2px 4px;
  font-size: 12px;
  font-weight: 400;
  text-align: LEFT;
  color: #999999;
  line-height: 20px;
  border-radius: 2px;
}

/* 全局变量 */
:root {
  /* 顶部导航栏高度 */
  --header-height: 48px;
  /* 整体屏幕内容的最大宽度 */
  --container-max-width: 1200px;
  /* 界面主颜色*/
  --main-color: #1677ff;
}

.ant-table {
  scrollbar-color: inherit;
}


/* 自定义顺时针旋转 */
.cus-rotate {
  animation: cusrotate 2s linear infinite;
}

@keyframes cusrotate {
  0% {
      transform: rotate(0);
  }

  100% {
      transform: rotate(360deg);
  }
}