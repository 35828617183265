.richeditor .w-e-select-list {
  z-index: 10;
}
.richeditor .w-e-text-placeholder {
  font-style: normal;
}
.richeditor[data-nopadding=true] .w-e-text-container [data-slate-editor] {
  padding: 0;
}
.richeditor[data-nopadding=true] .w-e-text-container [data-slate-editor] .w-e-image-container {
  margin: 0;
}
.richeditor .w-e-image-container {
  margin: 0 !important;
}
.richeditor .w-e-fill-blank {
  border: none;
  border-bottom: 1px solid #333;
  width: 72px;
  outline: none;
  background: transparent;
}
.richeditor .w-e-fill-blank::placeholder {
  position: absolute;
  left: calc(50% - 7px);
  top: 4px;
  font-size: 12px;
  color: #1677ff;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: 1px solid #1677ff;
  text-align: center;
  line-height: 14px;
}
.richeditor button[data-menu-key="fill-blank"]::before {
  margin-top: -3px;
  margin-right: 2px;
  content: "+";
  font-size: 20px;
  color: var(--w-e-toolbar-disabled-color);
}
.richeditor button[data-menu-key="fill-blank"]:not(.disabled) {
  color: #1677ff;
}
.richeditor button[data-menu-key="fill-blank"]:not(.disabled)::before {
  color: #1677ff;
}
.richeditor button[data-menu-key="fill-blank"]:not(.disabled):hover {
  background-color: #e8f1ff;
}
