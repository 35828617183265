.codeEditor-loading {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.codeEditor-loading .codeEditor-loading-in {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.codeEditor-loading .codeEditor-loading-in p {
  margin-top: 140px;
  color: #cccccc;
  font-size: 14px;
  line-height: 14px;
}
.codeEditor-loading .codeEditor-loading-in-line {
  position: absolute;
  left: calc(50% - 70px);
  top: calc(50% - 70px);
  width: 140px;
  height: 140px;
  z-index: 1;
  scale: 0.57;
}
.codeEditor-loading .codeEditor-loading-in-line div {
  position: absolute;
  width: 12px;
  height: 38px;
  border-radius: 6px;
  background: #1677ff;
  animation: load 1.04s ease infinite;
  -webkit-animation: load 1.04s ease infinite;
}
.codeEditor-loading .codeEditor-loading-in-line div:nth-child(1) {
  left: 13px;
  top: 50%;
  margin-top: -15px;
  transform: rotate(270deg);
  animation-delay: 0.13s;
  -webkit-animation-delay: 0.13s;
  opacity: 1;
}
.codeEditor-loading .codeEditor-loading-in-line div:nth-child(2) {
  left: 25px;
  top: 15px;
  transform: rotate(315deg);
  animation-delay: 0.26s;
  -webkit-animation-delay: 0.26s;
  opacity: 0.9;
}
.codeEditor-loading .codeEditor-loading-in-line div:nth-child(3) {
  left: 50%;
  top: 0;
  margin-left: -6px;
  transform: rotate(0);
  animation-delay: 0.39s;
  -webkit-animation-delay: 0.39s;
  opacity: 0.8;
}
.codeEditor-loading .codeEditor-loading-in-line div:nth-child(4) {
  top: 15px;
  right: 25px;
  transform: rotate(45deg);
  animation-delay: 0.52s;
  -webkit-animation-delay: 0.52s;
  opacity: 0.7;
}
.codeEditor-loading .codeEditor-loading-in-line div:nth-child(5) {
  right: 13px;
  top: 50%;
  margin-top: -15px;
  transform: rotate(90deg);
  animation-delay: 0.65s;
  -webkit-animation-delay: 0.65s;
  opacity: 0.6;
}
.codeEditor-loading .codeEditor-loading-in-line div:nth-child(6) {
  right: 25px;
  bottom: 15px;
  transform: rotate(135deg);
  animation-delay: 0.78s;
  -webkit-animation-delay: 0.78s;
  opacity: 0.5;
}
.codeEditor-loading .codeEditor-loading-in-line div:nth-child(7) {
  bottom: 0;
  left: 50%;
  margin-left: -6px;
  transform: rotate(180deg);
  animation-delay: 0.91s;
  -webkit-animation-delay: 0.91s;
  opacity: 0.4;
}
.codeEditor-loading .codeEditor-loading-in-line div:nth-child(8) {
  bottom: 15px;
  left: 25px;
  transform: rotate(225deg);
  animation-delay: 1.04s;
  -webkit-animation-delay: 1.04s;
  opacity: 0.3;
}
@keyframes load {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
@-webkit-keyframes load {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}
