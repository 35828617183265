.loginbg {
  width: 100%;
  height: 100%;
  background-image: url('../../assets/images/login/left-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 100px 0 60px 0;
}
.loginbg-top {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.loginbg-top img:nth-child(1) {
  margin-bottom: 80px;
}
.loginbg img {
  width: 100%;
}
