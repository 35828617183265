.footer-container {
  background: #080e26;
}
.footer-container-main {
  margin: 60px 0 0 0;
}
.footer-container-main .tabs {
  display: flex;
  width: 100%;
  max-width: 1440px;
  margin: auto auto 0;
  padding: 0 90px;
}
.footer-container-main .tabs .tab {
  margin-left: 30px;
  width: calc((100% - 90px) / 3);
  color: #cccccc;
}
.footer-container-main .tabs .tab .title {
  margin-bottom: 30px;
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
}
.footer-container-main .tabs .tab .item {
  margin-bottom: 10px;
}
.footer-container-main .tabs .tab .item p,
.footer-container-main .tabs .tab .item a {
  display: block;
  margin-bottom: 20px;
  font-size: 14px;
  color: #cccccc;
  line-height: 14px;
}
.footer-container-main .tabs .tab .item a:hover {
  text-decoration: underline;
}
.footer-container-main .company {
  border-top: 1px solid #575a69;
  padding: 14px 0;
  font-size: 12px;
  color: #cccccc;
  line-height: 12px;
  text-align: center;
}
.footer-container-main .company a {
  color: #cccccc;
}
.footer-container-main .company a:hover {
  color: #1677ff;
}
