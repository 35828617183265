.createblog {
  min-height: calc(100vh - 130px);
  background: #ffffff;
  border-radius: 4px;
  padding: 20px 30px 0;
}
.createblog-name {
  display: flex;
  margin-bottom: 30px;
}
.createblog-name-line {
  width: 4px;
  height: 20px;
  background: #1677ff;
  border-radius: 2px;
}
.createblog-name-text {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  line-height: 19px;
  margin-left: 10px;
}
.createblog .ant-form-item-label {
  text-align: start !important;
  width: 100px !important;
}
.createblog .ant-col-sm-8 {
  max-width: 14%;
}
.createblog .ant-col-sm-16 {
  max-width: 74%;
}
.createblog label::after {
  display: inline-block;
  margin-inline-end: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "" !important;
}
.createblog label::before {
  content: "" !important;
  margin-inline-end: 0 !important;
}
.createblog .ant-radio-input {
  opacity: 1 !important;
}
.createblog .ant-input-data-count {
  margin-bottom: 0 !important;
  position: absolute;
  bottom: 0;
  right: 5px;
}
.createblog .imageLogos {
  display: flex;
  justify-content: space-between;
}
.createblog .imageLogos .hoverAction {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 25px;
  padding-right: 25px;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 24px;
  background: rgba(0, 0, 0, 0.5);
}
.createblog .imageLogos .hoverAction img:hover {
  cursor: pointer;
}
.createblog .imageLogos .logoText {
  position: absolute;
  bottom: 0;
  left: 130px;
  color: #999999;
  font-size: 12px;
  width: 150px;
}
.createblog .aboutMeLog {
  position: absolute;
  left: 0px;
  bottom: -20px;
  font-size: 14px;
  color: #f5222d;
}
.createblog .rotate {
  animation: rotate 1s linear;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.createblog .updateIcon {
  position: absolute;
  top: 50px;
  left: -80px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.createblog .updateIcon-text {
  font-size: 12px;
  color: #1677ff;
  margin-left: 4px;
  text-decoration: underline;
}
.createblog .updateIcon .rotate {
  animation: rotate 1s linear;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.createblog .logoItem {
  position: relative;
  width: 118px;
  height: 66px;
  overflow: hidden;
  border-radius: 3px;
  background: linear-gradient(116deg, rgba(47, 84, 235, 0.06) 0%, rgba(47, 84, 235, 0.03) 100%);
}
.createblog .logoItem:hover {
  border: 1px solid #1677ff;
}
.createblog .activeImage {
  position: absolute;
  width: 16px;
  height: 16px;
  bottom: 4px;
  right: 4px;
}
.createblog .botton1 {
  width: 96px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  color: #666666;
  margin-left: 200px;
}
.createblog .botton2 {
  padding: 10px 24px 30px;
  background: #1677ff;
  border-radius: 2px;
  color: #fff;
  margin-left: 40px;
}
.createblog .ant-modal-content {
  border-radius: 4px !important;
}
.createblog .powerClass {
  border-radius: 4px !important;
  height: 84px;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
}
.createblog .ant-modal-close {
  display: none;
}
.createblog .gitHubImg {
  position: absolute;
  top: 8px;
  width: 16px;
  height: 16px;
  right: 10px;
}
.createblog .ant-input-textarea-show-count::after {
  position: absolute;
  right: 10px;
  bottom: 5px;
}
.createblog .ant-input-affix-wrapper {
  border-radius: 2px !important;
}
.createblog .ant-input {
  border-radius: 2px !important;
}
.createblog .drafting {
  position: absolute;
  top: 50px;
  right: -103px;
  font-size: 16px;
  font-weight: 400;
  color: #1677ff;
  line-height: 16px;
  cursor: pointer;
}
.createblog .labelAfter {
  position: absolute;
  color: #F5222D;
  font-size: 14px;
  left: -40px;
  top: 4px;
}
.createblog .ant-select-selector {
  border-radius: 2px !important;
}
.addModal .ant-modal-close {
  display: none !important;
}
.wenhaoModal .ant-modal-close-x,
.wenhaoModal .ant-modal-close {
  display: none !important;
}
