.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.container .container-heard {
  height: 48px;
  background: #252525;
  display: flex;
  align-items: center;
}
.container .container-heard .container-heard-select {
  width: 120px;
  height: 32px;
  background: #333333;
  border: 0.5px solid #454545;
  border-radius: 2px;
  margin-left: 30px;
  position: relative;
}
.container .container-heard .container-heard-select .icon {
  position: absolute;
  right: 10px;
  top: 6px;
  cursor: pointer;
  z-index: 1;
}
.container .container-heard .container-heard-select .ant-select-selector {
  position: relative;
  z-index: 9;
  width: 120px !important;
  height: 32px !important;
  border: none !important;
  background: transparent;
  box-shadow: none !important;
}
.container .container-heard .container-heard-select .ant-select-selector .ant-select-selection-placeholder {
  color: #CCCCCC !important;
  font-size: 14px;
}
.container .container-heard .container-heard-select .ant-select-selector .ant-select-selection-item {
  color: #CCCCCC !important;
  font-size: 14px;
}
.container .container-heard .container-heard-select .ant-select-selector:hover {
  border: none !important;
}
.container .container-heard .container-heard-select .ant-select-arrow {
  display: none !important;
}
.container .container-heard .container-heard-select .ant-select-dropdown {
  box-sizing: border-box;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25) !important;
  background-color: #252525 !important;
  border-radius: 2px !important;
  border: 0.5px solid #333333 !important;
  padding: 0 !important;
}
.container .container-heard .container-heard-select .ant-select-dropdown .ant-select-item-option-selected {
  background: #333333 !important;
  border-radius: 2px 2px 0px 0px !important;
  font-size: 13px;
  color: #ffffff !important;
}
.container .container-heard .container-heard-select .ant-select-dropdown .ant-select-item {
  color: #CCCCCC;
  font-size: 13px;
}
.container .container-footer {
  max-height: calc(100vh - 150px);
  background: #1e1e1e;
  min-height: 50px;
  display: flex;
  flex-direction: column;
}
.container .container-footer .container-footer-line {
  position: relative;
  z-index: 99;
}
.container .container-footer .footer-validate {
  display: flex;
  flex-direction: column;
  background: #1e1e1e;
  padding: 20px 30px;
  overflow-y: auto;
  max-height: calc(100vh - 200px);
  flex: 1;
  border-top: 0.5px solid #333333;
}
.container .container-footer .footer-validate::-webkit-scrollbar {
  width: 4px;
}
.container .container-footer .footer-validate::-webkit-scrollbar-track {
  background-color: transparent;
}
.container .container-footer .footer-validate::-webkit-scrollbar-thumb {
  background-color: #444444;
  border-radius: 2px;
}
.container .container-footer .footer-validate::-webkit-scrollbar-thumb:hover {
  background-color: #666666;
}
.container .container-footer .footer-validate .footer-validate-prompt {
  font-size: 14px;
  color: #cccccc;
}
.container .container-footer .footer-validate .validate-input {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.container .container-footer .footer-validate .validate-input span {
  font-size: 14px;
  color: #cccccc;
  white-space: nowrap;
  margin-right: 6px;
  min-width: 60px;
}
.container .container-footer .footer-validate .validate-input .ant-input {
  color: #ffffff;
  font-size: 14px;
  background: #333333 !important;
  border: 0.5px solid #454545 !important;
  border-radius: 2px !important;
}
.container .container-footer .footer-validate .validate-input .ant-input:focus {
  box-shadow: none !important;
}
.container .container-footer .footer-validate .validate-run {
  display: flex;
  justify-content: end;
  margin-top: 20px;
}
.container .container-footer .footer-validate .validate-run .ant-btn-primary:disabled {
  border-color: transparent;
  background: #1677ff;
  color: #ffffff;
  opacity: 0.65;
}
.container .container-footer .footer-validate .validate-error .validate-error-text {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #f5222d;
  background: rgba(245, 34, 45, 0.1);
  padding: 5px 10px;
  border-radius: 2px;
  line-height: 22px;
  word-break: break-word;
}
.container .container-footer .footer-validate .validate-error-msg {
  margin-top: 10px;
  font-size: 14px;
  color: #f5222d;
  line-height: 22px;
  word-break: break-word;
}
.container .container-footer .footer-validate .validate-success-text {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #30c213;
  height: 32px;
  background: rgba(122, 245, 98, 0.1);
  padding: 0 10px;
  border-radius: 2px;
}
.container .container-footer .footer-btns {
  display: flex;
  background: #1E1E1E;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  height: 48px;
  min-height: 48px;
  border-top: 0.5px solid #333333;
}
.container .container-footer .footer-btns .container-footer-left {
  position: relative;
  font-size: 14px;
  color: #1677ff;
  width: 64px;
  height: 32px;
  border: 1px solid #1677ff;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.container .container-footer .footer-btns .container-footer-left:hover {
  background: rgba(22, 119, 255, 0.1);
}
.container .container-footer .footer-btns .container-footer-rigth {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 102px;
  height: 32px;
  background: #1677ff;
  border-radius: 2px;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
}
.container .container-element {
  flex: 1;
  min-height: 100px;
}
.container .container-element-codeEditor {
  background: #1E1E1E;
}
