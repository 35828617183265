.hot-answer {
  margin-bottom: 20px;
}
.hot-answer-header {
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.hot-answer-header > div {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.hot-answer-header > div svg {
  transition: all 0.5s;
  fill: #1677FF;
}
.hot-answer-header > div svg:hover {
  fill: #4592FF;
}
.hot-answer-header > div span {
  margin-left: 10px;
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  user-select: none;
}
.hot-answer-header > span {
  display: flex;
  cursor: pointer;
}
.hot-answer-header > span svg {
  fill: #1677FF;
}
.hot-answer-header > span svg:hover {
  fill: #4592FF;
}
.hot-answer-ul {
  margin-left: 22px;
}
.hot-answer-ul div {
  height: 32px;
  font-size: 14px;
  color: #666666;
  line-height: 32px;
  white-space: nowrap;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  user-select: none;
}
.hot-answer-ul div:hover {
  color: #1677ff;
  cursor: pointer;
}
.hot-answer-empty {
  margin: 10px 0 0 22px;
  font-size: 14px;
  color: #cccccc;
}
