.detailcourse {
  position: relative;
  width: 100%;
  overflow: hidden;
  min-height: 100vh;
  padding: 48px 120px 0;
  background-color: #F5F6FA;
}
.detailcourse .mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
}
.detailcourse-preview {
  position: fixed;
  top: 0;
  right: 0;
  width: 124px;
  height: 48px;
  background: rgba(0, 0, 0, 0.5);
  font-size: 16px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.detailcourse .loding {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  opacity: 0.5;
  background: #000000;
}
.detailcourse-content {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 68px);
}
.detailcourse-content-tabs {
  width: 100%;
  height: 44px;
  background: #ffffff;
  border-radius: 10px 10px 0 0;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 0.5px solid #d9d9d9;
}
.detailcourse-content-tabs-item {
  font-size: 16px;
  font-family: PingFang SC, PingFang SC-500;
  font-weight: 500;
  text-align: LEFT;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}
.detailcourse-content-tabs-item .line {
  position: absolute;
  width: 100%;
  border-bottom: 2px solid #1677ff;
  bottom: 0;
}
.detailcourse-content-tro {
  margin-top: 16px;
  background: #ffffff;
  border-radius: 10px;
  padding: 20px 30px;
  display: flex;
}
.detailcourse-content-tro-left {
  width: calc(100% - 120px);
  height: 100%;
  margin-right: 60px;
}
.detailcourse-content-tro-left-title {
  width: 100%;
  margin-bottom: 16px;
}
.detailcourse-content-tro-left-title span:nth-child(1) {
  font-size: 18px;
  font-weight: 600;
  text-align: LEFT;
  color: #333333;
  line-height: 24px;
}
.detailcourse-content-tro-left-title span:nth-child(2) {
  margin-left: 10px;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #faad14;
  border-radius: 2px;
  text-align: center;
  line-height: 24px;
  font-size: 10px;
  font-weight: 300;
  text-align: CENTER;
  color: #faad14;
  padding: 1px 7px;
  min-width: 36px;
}
.detailcourse-content-tro-left-label {
  width: 100%;
  margin-bottom: 20px;
}
.detailcourse-content-tro-left-label span {
  line-height: 14px;
  height: 14px;
  font-size: 14px;
  font-weight: 400;
  color: #8bbbff;
  margin-right: 16px;
  user-select: none;
}
.detailcourse-content-tro-left-con {
  width: 100%;
  display: flex;
  align-items: flex-end;
}
.detailcourse-content-tro-left-con-p {
  font-size: 14px;
  font-weight: 400;
  text-align: LEFT;
  color: #333333;
  line-height: 22px;
  word-break: break-all;
}
.detailcourse-content-tro-left-con-span {
  width: 30px;
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
  text-align: center;
  color: #1677ff;
  line-height: 22px;
  cursor: pointer;
}
.detailcourse-content-tro-left-con .nozk {
  max-width: calc(100% - 30px);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.detailcourse-content-tro-rightcon {
  width: 120px;
}
.detailcourse-content-tro-rightcon-footer {
  margin-top: 10px;
  display: flex;
  align-items: center;
}
.detailcourse-content-tro-rightcon-footer .detailcourse-right-fresh {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 14px;
  width: fit-content;
  fill: #999999;
  cursor: pointer;
}
.detailcourse-content-tro-rightcon-footer .detailcourse-right-fresh svg {
  margin-right: 3px;
  fill: inherit;
}
.detailcourse-content-tro-rightcon-footer .detailcourse-right-fresh:hover {
  fill: #1677ff;
  color: #1677ff;
}
.detailcourse-content-tro-rightcon-footer .detailcourse-right-line {
  width: 1px;
  height: 14px;
  margin: 0 auto;
  background-color: #d9d9d9;
}
.detailcourse-content-tro-rightcon-footer .detailcourse-right-procress {
  height: 14px;
  font-size: 14px;
  font-weight: 400;
  text-align: LEFT;
  color: #999999;
  line-height: 14px;
}
.detailcourse-content-tro-right {
  width: 120px;
  background: #1677ff;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: LEFT;
  color: #ffffff;
  cursor: pointer;
  border-radius: 2px;
}
.detailcourse-content-tro-right:hover {
  background: #4592FF;
}
.detailcourse-content-course {
  width: 100%;
  background-color: #fff;
  border-radius: 0 0 10px 10px;
  padding: 20px 30px 10px;
  margin-bottom: 20px;
}
.detailcourse-content-nodata {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 100px;
  font-size: 14px;
  font-weight: 400;
  flex-direction: column;
  color: #999999;
  line-height: 22px;
}
.detailcourse-content-loading {
  height: calc(100vh - 128px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.warn .ant-modal-content {
  margin-inline-start: 0px !important;
  margin-block-start: 0px !important;
}
.warn .ant-modal-content .ant-modal-confirm-content {
  margin-inline-start: 38px !important;
}
