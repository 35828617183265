.workContainers {
  flex: 1;
  background: #fff;
  display: flex;
  overflow: hidden;
}
.container-work {
  width: 50%;
  min-width: 250px;
  position: relative;
  overflow: hidden;
}
