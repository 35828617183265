.blog-info {
  padding: 20px 30px 30px 30px;
}
.blog-info-name svg {
  margin-right: 5px;
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.blog-info-name span {
  font-size: 20px;
  font-weight: 600;
  color: #333333;
  line-height: 28px;
}
.blog-info-1 {
  margin-top: 16px;
  display: flex;
  align-items: center;
}
.blog-info-1 img {
  margin-right: 10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.blog-info-1 .username {
  margin-right: 10px;
  font-size: 12px;
  color: #666666;
}
.blog-info-1 .time {
  padding-right: 10px;
  font-size: 12px;
  color: #999999;
  border-right: 0.5px solid #e5e5e5;
}
.blog-info-1 .labname {
  padding-left: 10px;
  max-width: 120px;
  font-size: 12px;
  color: #73adff;
  overflow: hidden;
  white-space: nowrap;
  word-break: break-all;
  text-overflow: ellipsis;
  line-height: initial;
  cursor: default;
}
.blog-info-desc {
  margin-top: 16px;
  font-size: 14px;
  color: #666666;
  line-height: 26px;
  text-align: justify;
  white-space: pre-line;
}
