.remark-rigth {
  width: 280px;
  margin-left: 60px;
}
.remark-rigth .orderSelect {
  width: 100%;
  border-bottom: 1px solid #EBEBF0;
  margin-bottom: 16px;
  padding-bottom: 10px;
}
.remark-rigth .orderSelect .lianjie {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.remark-rigth .orderSelect .orderSelect-top {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  color: #999999;
  margin-bottom: 10px;
}
.remark-rigth .orderSelect .orderSelect-top .top-btns {
  display: flex;
  font-size: 14px;
  font-weight: 400;
  color: #1677ff;
  cursor: pointer;
}
.remark-rigth .orderSelect .orderList-select {
  width: 100%;
  max-height: 510px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 0.5px solid #e5e5e5;
  border-radius: 4px;
  box-shadow: 2px 2px 4px 0px rgba(41, 45, 51, 0.1);
}
.remark-rigth .orderSelect .orderList-select .orderList-select-top {
  height: 30px !important;
  border-bottom: 1px solid #E5E5E5;
  padding: 0 16px;
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  color: #333333;
}
.remark-rigth .orderSelect .orderList-select .orderList-select-items {
  flex: 1;
  overflow-y: auto;
  padding: 0 16px;
  width: 100%;
  cursor: pointer;
}
.remark-rigth .orderSelect .orderList-select .orderList-select-items::-webkit-scrollbar {
  display: none;
}
.remark-rigth .orderSelect .orderList-select .orderList-select-items .orderList-select-item {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  height: 54px;
  border-bottom: 1px solid #E5E5E5;
}
.remark-rigth .orderSelect .orderList-select .orderList-select-items .orderList-select-item .items-hover {
  top: 48px;
  right: 0;
  width: 400px;
  height: 90px;
  background: #fcfdff;
  border: 0.5px solid #e5e5e5;
  border-radius: 2px;
  box-shadow: 2px 2px 4px 0px rgba(92, 117, 153, 0.08);
  z-index: 9999;
}
.remark-rigth .orderSelect .orderList-select .orderList-select-items .orderList-select-item .item-value {
  flex: 1;
  margin-left: 16px;
}
.remark-rigth .orderSelect .orderList-select .orderList-select-items .orderList-select-item .item-value .order-text1 {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
}
.remark-rigth .orderSelect .orderList-select .orderList-select-items .orderList-select-item .item-value .order-text2 {
  width: 216px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 400;
  color: #888888;
}
.remark-rigth .orderSelect .orderList {
  box-sizing: border-box;
  margin-top: 10px;
}
.remark-rigth .orderSelect .orderList .orderItem {
  display: inline-block;
  padding: 4px 6px;
  font-size: 12px;
  font-family: PingFang SC, PingFang SC-400;
  font-weight: 400;
  color: #ffffff;
  border-radius: 10px;
  margin-right: 4px;
  margin-bottom: 4px;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
.order-tooltip {
  max-width: 400px;
}
.order-tooltip .ant-tooltip-content {
  width: 100%;
  height: 100%;
}
.order-tooltip .ant-tooltip-content .ant-tooltip-inner {
  background-color: #ffffff !important;
  min-height: 0px !important;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: 400;
  text-align: LEFT;
  color: #666666;
  line-height: 16px;
}
.order-tooltip .toolTip-div {
  display: flex;
  align-items: flex-start;
}
