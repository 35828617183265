.blog-directory-header {
  height: 36px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.blog-directory-header svg {
  transition: all 0.5s;
  fill: #1677FF;
}
.blog-directory-header svg:hover {
  fill: #4592FF;
}
.blog-directory-header span {
  margin-left: 10px;
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  user-select: none;
}
