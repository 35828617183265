.content-container {
  background-color: #f5f6fa;
  min-height: 100vh;
  padding-top: var(--header-height);
}
.content-container-content {
  max-width: var(--container-max-width);
  min-height: calc(100vh - var(--header-height));
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  gap: 30px;
}
.content-container-content-main {
  flex: 1;
  width: 0px;
}
