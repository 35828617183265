.container-sider {
  width: 196px;
  height: calc(100vh - var(--header-height) - 40px);
  background: #ffffff;
  border: 0.5px solid #f0f0f0;
  border-radius: 10px;
  position: sticky;
  top: calc(var(--header-height) + 20px);
  padding: 20px 16px;
}
.container-sider-item {
  height: 40px;
  border-radius: 4px;
  background-color: #fff;
  padding: 0 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  fill: #333333;
  font-size: 16px;
  font-weight: 400;
  text-align: LEFT;
  color: #333333;
}
.container-sider-item-title {
  margin-left: 12px;
  flex: 1;
}
.container-sider-highlight {
  background-color: #1677ff;
  color: white;
  fill: white;
}
.container-sider-dark {
  pointer-events: none;
  opacity: 0.06;
}
