.one {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.one-row {
  width: 100%;
  display: flex;
  align-items: center;
  height: 32px;
  background-color: white;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
}
.one-row img {
  width: 20px;
  height: 20px;
  margin-left: 20px;
}
.one-row-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 0;
  flex: 1;
  height: 18px;
  font-size: 14px;
  font-weight: 400;
  text-align: LEFT;
  line-height: 18px;
  margin-left: 10px;
  margin-right: 50px;
}
.one-row-procress {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 14px;
  margin-right: 20px;
}
.one-row:hover {
  background: #f5f6fa;
  color: #1677FF;
}
.one-row:hover .one-row-title {
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: 600;
  text-align: LEFT;
  line-height: 18px;
}
.one-highlight {
  background-color: rgba(48, 194, 19, 0.08);
}
.one-highlight-learning {
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  color: #30c213;
  padding-right: 10px;
  border-right: 1px solid #d9d9d9;
  margin-right: 10px;
}
.one-highlight:hover {
  background: rgba(48, 194, 19, 0.08);
  border: 0.5px solid #30c213;
  border-radius: 4px;
}
