.login {
  width: 100%;
  height: 100vh;
  display: flex;
}
.login-left {
  width: 55%;
  height: 100%;
}
.login-right {
  width: 45%;
  height: 100%;
  background-color: #ffffff;
}
.login-right-gov {
  height: 54px;
  text-align: center;
  padding: 20px;
  line-height: 14px;
  color: #999999;
}
.login-right-gov a {
  color: #999999;
}
.login-right-gov a:hover {
  color: #1677ff;
}
