.personal-title {
  font-size: 20px;
  font-weight: 600;
  text-align: LEFT;
  color: #333333;
  line-height: 20px;
  position: relative;
  margin-bottom: 16px !important;
}
.personal-title::before {
  position: absolute;
  content: '';
  width: 40px;
  height: 4px;
  background: var(--main-color);
  border-radius: 2px;
  bottom: -8px;
  left: 0px;
}
.personal-container-one {
  height: 242px;
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 20px;
  display: flex;
  align-items: flex-start;
}
.personal-container-one-right {
  flex: 1;
  margin-left: 20px;
}
.personal-container-one-right-item .title {
  font-size: 14px;
  font-weight: 600;
  text-align: LEFT;
  color: #333333;
  line-height: 14px;
  margin-bottom: 8px;
}
.personal-container-one-right-item .ant-input:disabled {
  background-color: transparent;
}
.personal-container-one-right-item .context {
  display: flex;
  align-items: center;
}
.personal-container-one-right-item .context .btn {
  min-width: 28px;
  margin-left: 10px;
  font-size: 14px;
  font-weight: 400;
  text-align: LEFT;
  color: #1677ff;
  line-height: 14px;
  cursor: pointer;
}
.personal-container-two {
  background: #ffffff;
  border-radius: 10px;
}
.personal-container-two-item {
  height: 64px;
  padding: 16px 20px;
  display: flex;
  align-items: center;
}
.personal-container-two-item img {
  width: 32px;
  height: 32px;
}
.personal-container-two-item-title {
  flex: 1;
  margin: 0 10px;
  font-size: 16px;
  font-weight: 400;
  text-align: LEFT;
  color: #333333;
  line-height: 16px;
}
.personal-container-two-item .ant-btn {
  padding-left: 2px;
  padding-right: 2px;
}
.personal-container-two-item .ant-btn:disabled {
  background: #cccccc;
  color: #ffffff;
}
.personal-container-two-line {
  width: calc(100% - 40px);
  margin: 0 auto;
  height: 0.5px !important;
  background-color: #d9d9d9;
}
.personal-personalInfo-container-logoimg {
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 80px;
  overflow: hidden;
  cursor: pointer;
}
.personal-personalInfo-container-logoimg .img {
  width: 80px;
  height: 80px;
  border-radius: 80px;
  overflow: hidden;
}
.personal-personalInfo-container-logoimg-hover {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 12px;
  width: 80px;
  height: 24px;
  background: rgba(0, 0, 0, 0.5);
}
