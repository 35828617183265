.three {
  width: 100%;
}
.three-stage {
  overflow: hidden;
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}
.three-stage span:nth-child(1) {
  height: 24px;
  background: linear-gradient(117deg, #ffce6c 52%, #fa9014 100%);
  border-radius: 8px;
  margin-right: 15px;
  font-size: 16px;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: 500;
  text-align: center;
  padding: 4px 12px;
  color: #ffffff;
}
.three-stage span:nth-child(2) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
  height: 16px;
  font-size: 16px;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: 600;
  text-align: LEFT;
  color: #333333;
  line-height: 16px;
}
.three-two {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
