.issuePage {
  background-color: #DCEBFF;
  height: calc(100vh - 48px);
}
.issuePage-main {
  margin: 0 auto;
  padding: 15px 0;
  max-width: var(--container-max-width);
  height: 100%;
}
