.remark-item {
  display: flex;
  width: 100%;
  align-items: start;
  margin-bottom: 16px;
}
.remark-item .userImg {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin-right: 10px;
}
.remark-item .userText {
  flex: 1;
  background: #ffffff;
  border: 0.5px solid rgba(22, 119, 255, 0.3);
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.remark-item .userText .userText-userName {
  width: 100%;
  height: 28px;
  border-bottom: 0.5px solid;
  display: flex;
  align-items: center;
  padding: 10px 8px;
  font-size: 12px;
  font-weight: 400;
  color: #333333;
}
.remark-item .userText .userText-value {
  flex: 1;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
}
