.content-container-old {
  background-color: #f5f6fa;
  min-height: calc(100vh - 85px);
}
.content-container-old-content {
  max-width: var(--container-max-width);
  width: 100%;
  display: flex;
  margin: 0 auto;
}
