.logoSelect .ant-modal-content {
  background: transparent;
  box-shadow: none;
}
.logoSelect .ant-modal-close {
  display: none !important;
}
.logoSelect .allLogo {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.logoSelect .topLogo {
  display: flex;
  align-items: center;
}
.logoSelect .topLogo .leftSelect {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 55px;
}
.logoSelect .topLogo .leftSelectNo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  cursor: not-allowed;
  margin-right: 55px;
}
.logoSelect .topLogo .leftSelect:hover {
  background: rgba(0, 0, 0, 0.1);
}
.logoSelect .topLogo .bigLogo {
  width: 640px;
  height: 360px;
  border-radius: 10px;
}
.logoSelect .topLogo .rigthSelect {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  cursor: pointer;
  margin-left: 55px;
}
.logoSelect .topLogo .rigthSelectNo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  cursor: not-allowed;
  margin-left: 55px;
}
.logoSelect .topLogo .rigthSelect:hover {
  background: rgba(0, 0, 0, 0.1);
}
.logoSelect .logoChange {
  display: flex;
  align-items: center;
  margin-top: 22px;
}
.logoSelect .logoChange .leftSelect {
  margin-right: 10px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 20px;
  height: 72px;
  border-radius: 10px;
}
.logoSelect .logoChange .leftSelectNo {
  margin-right: 10px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: not-allowed;
  width: 20px;
  height: 72px;
  border-radius: 10px;
}
.logoSelect .logoChange .rigthSelect {
  margin-left: 10px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 20px;
  height: 72px;
  border-radius: 10px;
}
.logoSelect .logoChange .rigthSelectNo {
  margin-left: 10px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: not-allowed;
  width: 20px;
  height: 72px;
  border-radius: 10px;
}
.logoSelect .logoChange .rigthSelect:hover {
  background: rgba(0, 0, 0, 0.1);
}
.logoSelect .logoChange .leftSelect:hover {
  background: rgba(0, 0, 0, 0.1);
}
.logoSelect .logoChange .imageLsit {
  width: 820px;
  position: relative;
  height: 82px;
  overflow-x: hidden;
}
.logoSelect .logoChange .imageLsit .imageLsits {
  display: flex;
  overflow-x: hidden;
  flex-wrap: nowrap;
  position: absolute;
}
.logoSelect .logoChange .imageLsit .item {
  position: relative;
  width: 128px;
  height: 72px;
  border-radius: 10px;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
}
.logoSelect .bottoms {
  display: flex;
  margin-top: 40px;
}
.logoSelect .bottoms .bottomLeft {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 88px;
  height: 32px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
}
.logoSelect .bottoms .bottomLeft:hover {
  background: rgba(255, 255, 255, 0.1);
}
.logoSelect .bottoms .bottomRigth {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 88px;
  height: 32px;
  background: #1677ff;
  border-radius: 2px;
  margin-left: 30px;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
}
.logoSelect .bottoms .bottomRigth:hover {
  background: #4592ff;
}
