.line {
  user-select: none;
  background-color: #333333;
  position: relative;
}
.line:hover::before {
  background-color: #1677FF;
}
.linerow {
  width: 100%;
  height: 2px;
  cursor: ns-resize;
}
.linerow:hover::before {
  position: absolute;
  content: '';
  width: 100%;
  height: 4px;
  top: calc((2px - 4px) / 2);
  left: 0;
  z-index: 99;
}
.linecol {
  width: 2px;
  height: 100%;
  cursor: ew-resize;
}
.linecol:hover::before {
  position: absolute;
  content: '';
  width: 4px;
  height: 100%;
  top: 0;
  left: calc((2px - 4px) / 2);
  z-index: 99;
}
