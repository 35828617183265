.blog-env {
  margin-bottom: 10px;
}
.blog-env-header {
  height: 36px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.blog-env-header svg {
  transition: all 0.5s;
  fill: #1677FF;
}
.blog-env-header svg:hover {
  fill: #4592FF;
}
.blog-env-header span {
  margin-left: 10px;
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  user-select: none;
}
.blog-env-info {
  margin-left: 22px;
}
.blog-env-info > div {
  display: flex;
}
.blog-env-info-left {
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  font-weight: 600;
  color: #333333;
}
.blog-env-info-right {
  flex: 1;
  overflow: hidden;
}
.blog-env-info-right span {
  display: block;
  margin: 7px 0;
  width: fit-content;
  max-width: 100%;
  font-size: 14px;
  color: #666666;
  white-space: nowrap;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: initial;
  cursor: pointer;
}
