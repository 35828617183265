.courseAnswerList {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.courseAnswerList-Heard {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.courseAnswerList-Heard-left {
  display: flex;
  align-items: center;
}
.courseAnswerList-Heard-left .line {
  width: 4px;
  height: 18px;
  background: #1677ff;
  border-radius: 2px;
}
.courseAnswerList-Heard-left .text {
  font-size: 18px;
  font-weight: 600;
  color: #333333;
  line-height: 18px;
  margin-left: 10px;
}
.courseAnswerList-Heard-rigth {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 96px;
  height: 32px;
  background: #1677ff;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 14px;
  cursor: pointer;
}
.courseAnswerList-content {
  width: 100%;
  background: #ffffff;
  border-radius: 0 0 10px 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.courseAnswerList-content .loding1 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.courseAnswerList-content .tableloading-line div {
  background: #1677ff !important;
}
.courseAnswerList-content .courseAnswerList-my {
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 60px 0;
}
.courseAnswerList-content .courseAnswerList-my .labs-my-img {
  width: 140px;
}
.courseAnswerList-content .courseAnswerList-my .labs-btn {
  width: 120px;
  height: 40px;
  background: #1677ff;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #ffffff;
  margin-top: 30px;
}
.courseAnswerList-content .courseAnswerList-my .labs-btn:hover {
  cursor: pointer;
  background: #4592ff;
}
.courseAnswerList-content .courseAnswerList-my .labs-my-text {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  margin-top: 20px;
}
.courseAnswerList-content .courseAnswerList-my .creatBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 103px;
  height: 40px;
  background: #1677ff;
  border-radius: 2px;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  margin-top: 30px;
}
.courseAnswerList-content .courseAnswerList-my .creatBtn:hover {
  cursor: pointer;
  background: #4592FF;
}
.courseAnswerList-content .issueItem {
  padding: 20px 30px 0;
  display: flex;
  width: 100%;
}
.courseAnswerList-content .issueItem:hover {
  cursor: pointer;
  background: #fafafa;
}
.courseAnswerList-content .issueItem-image {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
}
.courseAnswerList-content .issueItem .issueItem-content {
  display: flex;
  flex: 1;
  margin-left: 20px;
  padding-bottom: 20px;
  justify-content: space-between;
  overflow: hidden;
  border-bottom: 0.5px solid #d9d9d9;
}
.courseAnswerList-content .issueItem .issueItem-content-left {
  flex: 1;
  overflow: hidden;
}
.courseAnswerList-content .issueItem .issueItem-content-left-name {
  font-size: 12px;
  font-family: PingFang SC, PingFang SC-400;
  font-weight: 400;
  color: #999999;
}
.courseAnswerList-content .issueItem .issueItem-content-left-text {
  max-width: 100%;
  margin-top: 12px;
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 22px;
}
.courseAnswerList-content .issueItem .issueItem-content-left-text span:hover {
  color: #1677FF;
}
.courseAnswerList-content .issueItem .issueItem-content-left .lable {
  margin-top: 12px;
  font-size: 12px;
  font-weight: 400;
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  height: 19px;
  overflow: hidden;
}
.courseAnswerList-content .issueItem .issueItem-content-left .lable .spanLable {
  padding: 3px 6px;
  border-radius: 10px;
  background: #e1e9f5;
  font-size: 11px;
  font-weight: 400;
  color: #666666;
  margin-left: 4px;
  margin-bottom: 1px;
}
.courseAnswerList-content .issueItem .issueItem-content-left .content-address {
  display: flex;
  margin-top: 16px;
}
.courseAnswerList-content .issueItem .issueItem-content-left .content-address div {
  font-size: 12px;
  color: #1677FF;
}
.courseAnswerList-content .issueItem .issueItem-content-left .content-address div span:first-child {
  color: #999999 !important;
}
.courseAnswerList-content .issueItem .issueItem-content-left .content-address div span:nth-child(2) {
  color: #1677FF;
  text-decoration: underline;
}
.courseAnswerList-content .issueItem .issueItem-content-left .content-address div span:nth-child(2):hover {
  color: #4592ff;
}
.courseAnswerList-content .issueItem .issueItem-content-rigth {
  display: flex;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  margin-left: 190px;
}
.courseAnswerList-content .issueItem .issueItem-content-rigth:hover {
  color: #1677FF;
}
.courseAnswerList-content .issueItem:last-child .issueItem-content {
  border: none !important;
}
.courseAnswerList-Pagination {
  margin-top: 16px;
  margin-bottom: 20px;
}
.courseAnswerList-Pagination .ant-pagination .ant-pagination-prev {
  width: 32px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 2px !important;
}
.courseAnswerList-Pagination .ant-pagination-next {
  width: 32px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 2px !important;
}
.courseAnswerList-Pagination .ant-pagination-item-link {
  border-radius: 0px !important;
}
.courseAnswerList-Pagination .ant-pagination-item {
  background: #ffffff !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 2px !important;
}
.courseAnswerList-Pagination .ant-pagination .ant-pagination-item-active a {
  background: #1677ff;
  color: #fff;
}
.courseAnswerList-Pagination .ant-pagination .ant-pagination-item-active:hover a {
  color: #fff !important;
}
.courseAnswerList-Pagination .anticon {
  vertical-align: 0em;
}
.courseAnswerList-Pagination .ant-select-single .ant-select-selector {
  border-radius: 2px !important;
}
.courseAnswerList-Pagination .ant-pagination .ant-pagination-options-quick-jumper input {
  border-radius: 2px !important;
}
