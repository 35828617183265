.navitem {
  height: 40px;
  border-radius: 4px;
  background-color: #fff;
  padding: 0 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  fill: #333333;
  font-size: 16px;
  font-weight: 400;
  text-align: LEFT;
  color: #333333;
}
.navitem-title {
  margin-left: 12px;
  flex: 1;
}
.navitem-highlight {
  background-color: var(--main-color);
  color: white;
  fill: white;
}
.navitem-dark {
  pointer-events: none;
  opacity: 0.5;
}
