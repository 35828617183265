.createanswer-modal .ant-modal {
  border-radius: 4px !important;
  overflow: hidden;
}
.createanswer-modal .ant-modal-content {
  background: #ffffff;
  padding: 0px;
}
.createanswer-modal .ant-modal-content .ant-modal-body {
  width: 100%;
  height: 716px;
  padding: 30px 50px;
}
.createanswer-title {
  font-size: 18px;
  font-weight: 600;
  text-align: CENTER;
  color: #333333;
  line-height: 18px;
  margin-bottom: 30px;
}
.createanswer-main {
  height: calc(100% - 48px);
  overflow-y: auto;
}
.createanswer-main::-webkit-scrollbar {
  display: none;
}
.createanswer-main .ant-form .ant-select-selector,
.createanswer-main .ant-form .ant-input-affix-wrapper {
  border-radius: 2px !important;
}
.createanswer-main .ant-form .ant-form-item-explain-error {
  line-height: 20px;
}
.createanswer-main .ant-form .ant-form-item {
  margin-bottom: 20px;
}
.createanswer-main-labels-add {
  width: fit-content;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  text-align: LEFT;
  color: #1677ff;
  line-height: 14px;
}
.createanswer-main-labels-add span {
  margin-left: 4px;
}
.createanswer-main-labels-remove {
  cursor: pointer;
  --color: #999999;
  transform: translateY(-10px);
  margin-left: 10px;
}
.createanswer-main-labels-remove circle {
  stroke: var(--color);
}
.createanswer-main-labels-remove rect {
  fill: var(--color);
}
.createanswer-main-labels-remove:hover {
  --color: #1677ff;
}
.createanswer-main-labels-item {
  display: flex;
  align-items: center;
}
.createanswer-main-permission-tip {
  display: flex;
  align-items: start;
}
.createanswer-main-permission-tip p {
  margin: 0px;
  margin-left: 2px;
  font-size: 12px;
  font-weight: 400;
  text-align: LEFT;
  color: #999999;
  line-height: 18px;
}
.createanswer-main-submit {
  display: flex;
  justify-content: center;
  align-items: center;
}
.createanswer-main-submit-con {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.createanswer-main-btn {
  width: 134px;
  height: var(--top);
  --top: 32px;
  background: #1677ff;
  border-radius: 2px;
  display: flex;
  align-items: center;
  position: relative;
}
.createanswer-main-btn-dark {
  background-color: #cccccc;
  pointer-events: none;
}
.createanswer-main-btn .center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.createanswer-main-btn-left {
  flex: 1;
  height: 100%;
  color: white;
  cursor: pointer;
  font-size: 14px;
}
.createanswer-main-btn-left:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
.createanswer-main-btn-right {
  width: 22px;
  height: 100%;
  position: relative;
  color: white;
  fill: white;
}
.createanswer-main-btn-right::before {
  position: absolute;
  content: '';
  height: 14px;
  top: 7px;
  left: -0.5px;
  width: 0.5px;
  background-color: rgba(255, 255, 255, 0.5);
}
.createanswer-main-btn-right:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
.createanswer-main-btn-menus {
  position: absolute;
  top: var(--top);
  left: 0;
  width: 100%;
  background: #ffffff;
  border: 0.5px solid #e5e5e5;
  border-radius: 2px;
  box-shadow: 2px 2px 4px 0px rgba(41, 45, 51, 0.1);
  overflow: hidden;
  transition: 0.2s;
}
.createanswer-main-btn-menus-item {
  height: 28px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.createanswer-main-btn-menus-item span {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.createanswer-main-btn-menus-item:hover {
  background: #f0f2f5;
}
