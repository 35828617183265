.breadcrumb {
  padding-top: 16px;
  height: 36px;
  display: flex;
  align-items: center;
  max-width: var(--container-max-width);
  width: 100%;
  margin: 0 auto;
}
.breadcrumb-item {
  line-height: 14px;
  color: #999999;
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.breadcrumb-item:last-child {
  cursor: default;
  color: #333333;
}
.breadcrumb img {
  margin: 0 4px;
  width: 16px;
  height: 16px;
}
