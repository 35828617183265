.issueList {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.issueList-Heard {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.issueList-Heard-left {
  display: flex;
  align-items: center;
}
.issueList-Heard-left .line {
  width: 4px;
  height: 18px;
  background: #1677ff;
  border-radius: 2px;
}
.issueList-Heard-left .text {
  font-size: 18px;
  font-weight: 600;
  color: #333333;
  line-height: 18px;
  margin-left: 10px;
}
.issueList-Heard-rigth {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 96px;
  height: 32px;
  background: #1677ff;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  line-height: 14px;
  cursor: pointer;
}
.issueList-content {
  flex: 1;
  width: 100%;
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  padding: 8px 0;
}
.issueList-content > div::-webkit-scrollbar {
  display: none;
}
.issueList-content .tableloading-line div {
  background: #1677ff !important;
}
.issueList-content .issueList-my {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.issueList-content .issueList-my .labs-my-img {
  width: 140px;
}
.issueList-content .issueList-my .labs-my-text {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  margin-top: 20px;
}
.issueList-content .issueList-my .creatBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 103px;
  height: 40px;
  background: #1677ff;
  border-radius: 2px;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  margin-top: 30px;
}
.issueList-content .issueList-my .creatBtn:hover {
  cursor: pointer;
  background: #4592FF;
}
.issueList-content .issueItem {
  padding: 10px 20px;
  border-bottom: 1px solid #EBEBF0;
}
.issueList-content .issueItem:hover {
  cursor: pointer;
  background: #fafafa;
}
.issueList-content .issueItem .issueItem-top {
  display: flex;
  align-items: start;
  justify-content: space-between;
}
.issueList-content .issueItem .issueItem-top .issueItem-top-left {
  display: flex;
  align-items: start;
  font-size: 16px;
  font-weight: 400;
  color: #333333;
}
.issueList-content .issueItem .issueItem-top .issueItem-top-left .issueItem-question:hover {
  color: #1677ff;
}
.issueList-content .issueItem .issueItem-top .issueItem-top-left .issueItem-lable {
  padding: 3px 6px;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  white-space: nowrap;
  margin-left: 4px;
}
.issueList-content .issueItem .issueItem-top .issueItem-top-rigth {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #666;
  margin-left: 30px;
}
.issueList-content .issueItem .issueItem-top .issueItem-top-rigth:hover {
  color: #1677ff;
}
.issueList-content .issueItem .issueItem-Time {
  display: flex;
  align-items: center;
  margin: 8px 0 0 26px;
}
.issueList-content .issueItem .issueItem-Time .userName {
  font-size: 12px;
  font-weight: 400;
  color: #666666;
}
.issueList-content .issueItem .issueItem-Time .itemTime {
  color: #999999;
  font-size: 12px;
  font-weight: 400;
  margin-left: 8px;
}
.issueList-Pagination {
  margin-top: 16px;
}
.issueList-Pagination .ant-pagination .ant-pagination-prev {
  width: 32px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 2px !important;
}
.issueList-Pagination .ant-pagination-next {
  width: 32px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 2px !important;
}
.issueList-Pagination .ant-pagination-item-link {
  border-radius: 0px !important;
}
.issueList-Pagination .ant-pagination-item {
  background: #ffffff !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 2px !important;
}
.issueList-Pagination .ant-pagination .ant-pagination-item-active a {
  background: #1677ff;
  color: #fff;
}
.issueList-Pagination .ant-pagination .ant-pagination-item-active:hover a {
  color: #fff !important;
}
.issueList-Pagination .anticon {
  vertical-align: 0em;
}
.issueList-Pagination .ant-select-single .ant-select-selector {
  border-radius: 2px !important;
}
.issueList-Pagination .ant-pagination .ant-pagination-options-quick-jumper input {
  border-radius: 2px !important;
}
