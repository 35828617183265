.container-element-richEditor {
  background: #FFFFFF;
  overflow-y: scroll;
}
.container-element-richEditor .richeditor > div {
  overflow-y: visible !important;
}
.container-element-richEditor .w-e-text-container {
  padding: 0 20px;
}
