.img-crop-modal .ant-modal {
  top: 0;
}
.img-crop-modal {
  display: flex;
  justify-content: center;
  align-items: center;
}
.imageItem {
  height: 66px;
  overflow: hidden;
  overflow: auto;
}
.imageItem .ant-upload-list-item-container {
  width: 118px !important;
  height: 66px !important;
  margin-block: 0 0 !important;
  margin-inline: 0 0 !important;
}
.imageItem .ant-upload-list-item {
  padding: 0 !important;
  border: none !important;
}
.imageItem .ant-upload-select {
  width: 118px !important;
  height: 66px !important;
  flex-direction: column !important;
}
.imageItem .ant-upload {
  flex-direction: column !important;
}
.imageItem .imageShow {
  width: 120px;
  height: 66px;
  background: #d9d9d9;
  position: relative;
}
.imageItem .imageShow:hover {
  border: 1px solid #2f54eb;
  border-radius: 2px;
  cursor: pointer;
}
.imageItem .imageHover {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 24px;
  opacity: 0.5;
  background: #000000;
  border-radius: 0 0 1px 1px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.imageItem .rubbish {
  width: 16px;
  height: 16px;
  margin-right: 15px;
}
.imageItem .action {
  width: 16px;
  height: 16px;
}
.imageItem .showImage {
  width: 118px !important;
  height: 66px !important;
}
.imageItem .ant-upload {
  margin-inline-end: 0px !important;
  margin-bottom: 0px !important;
}
.imageItem .ant-upload.ant-upload-select {
  border-radius: 2px !important;
  color: #999999 !important;
}
.imageItem .ant-upload-list-item::before {
  bottom: 0 !important;
  z-index: 1;
  width: calc(100% - 0px) !important;
  height: 24px !important;
}
.imageItem .ant-upload-list-item-actions {
  bottom: 0 !important;
}
.imageItem-focus .ant-upload-select {
  width: 64px !important;
}
