.pripolicy {
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  color: #999999;
  line-height: 12px;
}
.pripolicy-right {
  cursor: pointer;
  color: #333333;
}
.pripolicy-right:hover {
  color: #1677ff;
}
