.blog-remark {
  position: relative;
  background-color: #F7F8FA;
  min-height: calc(100vh);
  padding-top: 48px;
}
.blog-remark-main {
  margin: 0 auto 20px;
  padding: 15px 0;
  max-width: var(--container-max-width);
  min-height: calc(100vh - 85px);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.blog-remark-main-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border-radius: 10px 10px 0 0;
  padding: 16px 30px;
}
.blog-remark-main-top-left {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  color: #333333;
}
.blog-remark-main-top-left .line {
  width: 4px;
  height: 18px;
  background: #1677ff;
  border-radius: 2px;
  margin-right: 10px;
}
.blog-remark-main-top-rigth {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 88px;
  height: 32px;
  background: #1677ff;
  border-radius: 2px;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
}
