.enter-workspace-loading {
  position: fixed;
  inset: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0b0c0d;
}
.enter-workspace-loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.enter-workspace-loading-container .workspace-gifloading {
  width: 320px;
  height: 320px;
  position: relative;
}
.enter-workspace-loading-container .workspace-gifloading .svg1 {
  width: 100%;
  height: 100%;
}
.enter-workspace-loading-container .workspace-gifloading .svg2 {
  position: absolute;
  inset: 49px;
}
.enter-workspace-loading-container .workspace-gifloading .svg3 {
  position: absolute;
  left: 50%;
  top: 132px;
  transform: translateX(-50%);
  animation: opacity 6s ease-in-out infinite;
}
.enter-workspace-loading-container .workspace-textloading {
  margin-top: 40px;
  height: 160px;
  overflow: hidden;
}
.enter-workspace-loading-container .workspace-textloading > div {
  transform: translateY(0);
}
.enter-workspace-loading-container .workspace-textloading > div.move {
  transition: 1s;
  transform: translateY(-44px);
}
.enter-workspace-loading-container .workspace-textloading > div > div {
  background-image: linear-gradient(90deg, #3589ff 0%, #28edff 100%);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  height: 20px;
  text-shadow: 1px 1px 0px 0px rgba(0, 29, 102, 0.8);
  text-align: center;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 24px;
  opacity: 0.7;
  transition: 1s;
}
.enter-workspace-loading-container .workspace-textloading > div > div.active {
  font-size: 20px;
  font-weight: 600;
  opacity: 1;
}
.enter-workspace-loading .workspace-anticlockwise {
  animation: rotate1 8s linear infinite;
}
.enter-workspace-loading .workspace-clockwise {
  animation: rotate2 8s linear infinite;
}
@keyframes rotate1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
@keyframes rotate2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes opacity {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
}
