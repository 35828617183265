.examNotice {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.examNotice .ant-modal-close {
  display: none !important;
}
.examNotice-containt {
  width: 100%;
  height: 100%;
}
.examNotice-containt-top {
  display: flex;
  align-items: center;
}
.examNotice-containt-top .text {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  margin-left: 16px;
}
.examNotice-containt-text {
  margin-left: 38px;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
}
.examNotice-containt-btns {
  width: 100%;
  display: flex;
  justify-content: end;
  margin-top: 23px;
}
.examNotice-containt-btns-left {
  width: 88px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  color: #666666;
  margin-left: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.examNotice-containt-btns-left:hover {
  border: 1px solid #1677FF;
  color: #1677FF;
}
.examNotice-containt-btns-rigth {
  width: 88px;
  height: 32px;
  background: #1677ff;
  border-radius: 2px;
  color: #fff;
}
